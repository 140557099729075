function fullHeight() {
    (function ($) {
        var heightBanner = $(window).outerHeight();
        $(".full-height").css("height", heightBanner);
        $(".min-fullheight").css("min-height", heightBanner);
        $('.half-height').css("height", heightBanner / 2);
        $('.nofull-height').css("height", heightBanner * 0.7);
    })(jQuery);
}

function fullsizeBanner(id) {
	var banner = jQuery(id);
	var image = banner.data('image-src');
	var video = banner.data('video-src');
	var mp4video = banner.data('mp4-src');

	banner.background({
		source: {
			poster: image,
			mp4: mp4video,
			video: video
		}
	});
}

function scrollFixed() {
    var n = 0,
        t = $('.navbar-default');
    $(window).scroll(function() {
        var s = $(window).scrollTop();
        s <= 178 || $(".drawer").hasClass("is-active") || $(".drawer--search").hasClass("is-active") ? (t.removeClass("is-hidden"), t.removeClass("is-scrolled"), $('body').removeClass('scroll-is-active')) : s >= n ? (t.addClass("is-hidden"), t.addClass("is-scrolled"), $('body').addClass('scroll-is-active')) : t.removeClass("is-hidden"), n = s
    });
}

scrollFixed();


$(document).scroll(function() {
    var y = $(document).scrollTop(), //get page y value 
        header = $(".navbar-default"); // your div id
    if(y >= 30)  {
        header.addClass('fixed-to-top');
    } else {
        header.removeClass('fixed-to-top');
    }
});

function mobileNavToggle() {
	$(".drop-toggle").on('click', function(){
		$(this).closest('li').toggleClass('open');
	});
}
mobileNavToggle();

$(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top - 100
    }, 500);
});


jQuery(window).resize(function () {
    fullHeight();
});

$( document ).ready(function() {
	fullHeight();
    fullsizeBanner('#hero');
    fullsizeBanner('#banner1');
    fullsizeBanner('#banner2');
    fullsizeBanner('#banner3');
    fullsizeBanner('#banner4');
});
